import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BannerModule, PartnerModule, UtilityModule } from '@skykick/core';
import { AbstractUserProvider, AuthModule } from '@skykick/platform-identity-auth-auth0-angular';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';
import { HasBackupPermissionGuard } from './routing/guards/has-backup-permission.guard';
import { InitializationService } from './routing/login/initialization.service';
import { SEWRInterceptor } from './routing/sewr/sewr-interceptor.service';

function getSewrDomain() {
    return window.location.hostname.includes('.com') ? 'https://routing.skykick.com' : 'https://nonprod-routing.skykick.dev';
}

@NgModule({
    imports: [
        // @angular modules
        HttpClientModule,
        // @skykick/core modules
        BannerModule,
        PartnerModule,
        AuthModule.forRoot({
            LicenseAssignmentServiceBaseUrl: environment.licensesAssignmentBaseAddress + '/licenseassignment/api/v1',
            SewrSystemDomain: getSewrDomain()
        }),
        UtilityModule.forRoot({
            sewrSystemDomain: getSewrDomain(),
        }),
        // 3rd party modules
        TranslateModule,
        ToastrModule.forRoot()
        // Application modules
    ],
    providers: [
        CookieService,
        // Service for retrieving and authorizing http requests automatically
        { provide: AbstractUserProvider, useExisting: environment.userProvider },
        // Register a temporary fix for expired login redirect handler. NOTE: This needs to be addressed ASAP. CM #76699 / PS #70222
        {
            provide: APP_INITIALIZER,
            useFactory: (initializationService: InitializationService) => async () => initializationService.run(),
            deps: [InitializationService, TranslateService],
            multi: true
        },
        // Service for routing http requests through sewr automatically.
        { provide: HTTP_INTERCEPTORS, useClass: SEWRInterceptor, multi: true },
        // Service for providing application translations. (translations located in /assets/i18n)
        TranslateService,
        // Service for displaying toast messages to the user.
        ToastrService,
        /* ADDITIONAL GLOBAL SERVICES */
        { provide: 'Window',  useValue: window },
        HasBackupPermissionGuard
    ],
    exports: [
        BannerModule,
        TranslateModule
    ]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() core: CoreModule) {
        if (core) {
            // CoreModule should only be loaded a single time within AppModule.
            throw new Error('CoreModule already loaded.');
        }
    }
}
